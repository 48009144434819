export const supplierRoutes = {
  home: "/product",
  dashboard: "/product",
  products: {
    index: "/product",
    upload: "/product/upload",
    edit: {
      index: "/product/edit",
      id: (id: string) => `/product/edit/${id}`,
    },
    view: (id: string) => `/product/${id}`,
    create: "/product/create",
  },
  dam: {
    tools: {
      index: "/dam/tools",
      imageResizer: "/dam/tools/image-resizer",
      imageUpscaler: "/dam/tools/image-upscaler",
      watermarkRemoval: "/dam/tools/watermark-removal",
      backgroundGenerator: "/dam/tools/ai-generate-background",
      backgroundRemover: "/dam/tools/background-remover",
      attributesGenerator: "/dam/tools/attributes-generator",
    },
    library: {
      index: "/dam/image-library",
    },
    task: { index: "/dam/task" },
    image: {
      view: (id: string) => `/dam/image/${id}/view`,
    },
  },
  localization: {
    index: "/localization",
    task: "/localization/task",
    edit: {
      index: "/localization/edit",
      id: (id: string) => `/localization/edit/${id}`,
    },
    view: (id: string) => `/localization/${id}`,
    create: "/localization/create",
  },
  enrichment: {
    create: "/enrichment/create",
    index: "/enrichment",
  },
  distribution: {
    index: "/distribution",
  },
  companyProfile: {
    index: "/company-profile",
    edit: "/company-profile/edit",
  },
  accountManagement: {
    index: "/account-management",
    id: (id: string) => `/account-management/${id}`,
  },
  catalog: {
    index: "/catalog",
    create: "/catalog/create",
    edit: (id: string) => `/catalog/edit/${id}`,
    editIndex: "/catalog/edit/[id]",
    addProducts: (id: string) => `/catalog/edit/${id}?tab=product-selection`,
  },
  variant: {
    edit: (id: string) => `/variant/edit/${id}`,
  },
  saleOpportunity: {
    index: "/sale-opportunity",
    subscribe: "/sale-opportunity/subscribe",
  },
  gettingStarted: {
    index: "/product",
  },
  login: {
    index: "/login",
    forgotPassword: "/login/forgot-password",
    terms: "/terms",
  },
  logout: "/logout",
  export: {
    templates: {
      index: "/export/templates",
      create: "/export/templates/create",
      detail: (id: string) => `/export/templates/${id}`,
    },
    settings: "/export/settings",
    history: "/export/history",
  },
  settings: {
    index: "/settings",
    updateEmail: "/settings/update-email",
    updatePassword: "/settings/update-password",
    apiKey: "/settings/api-key",
  },
  quotationRequests: {
    index: "/enquiry",
    view: (id: string) => `/enquiry/${id}`,
  },
  attribute: {
    index: "/attributes",
    add: "/attributes/add",
  },
  channels: {
    index: "/channels",
    configuration: (channel: string) => `/channels/configuration/${channel}`,
    history: (channel: string) => `/channels/history/${channel}`,
  },
  smartUpload: {
    upload: "/smart-upload/upload-file",
    setHeader: (taskId: string) => `/smart-upload/set-header/${taskId}`,
    mapFields: (taskId: string) => `/smart-upload/map-fields/${taskId}`,
    setData: (taskId: string) => `/smart-upload/set-data/${taskId}`,
    confirm: (taskId: string) => `/smart-upload/confirm/${taskId}`,
    pending: (taskId: string) => `/smart-upload/pending/${taskId}`,
    uploadImages: `/smart-upload/upload-images`,
  },
  template: {
    download: "/template/download",
  },
  featureToggles: "/feature",
  helpCenter: "https://help.trustana.com/en/",
  support: "https://www.trustana.com/support",
  bookDemo: "https://www.trustana.com/book-demo",
  privacyAndTerms: "https://www.trustana.com/privacy-and-terms",
};

export const BASE_BACKEND_RESTFUL_URL = `${process.env.NEXT_PUBLIC_BACKEND_URL}/restful/v1`;

export const ACCESS_CONTROL_REDIRECT_URL = supplierRoutes.bookDemo;

export const BASE_FILE_UPLOAD_RESTFUL_URL = `${process.env.NEXT_PUBLIC_FILE_BACKEND_URL}/files`;

export const PUBLIC_FUNCTION_URL = process.env.NEXT_PUBLIC_FUNCTION_URL;

export const NON_AUTHENTICATED_PREFIXES = [
  supplierRoutes.login.index,
  supplierRoutes.logout,
  supplierRoutes.featureToggles,
];
