"use client";

import { supplierRoutes } from "@/src/constants/routes";
import { Namespace } from "@/src/i18n/namespace";
import { Flex, Text, Title, theme } from "@/src/pattern-library";
import { useTranslations } from "next-intl";
import Link from "next/link";
import Image from "next/image";
import { useRedirectToHome } from "./useRedirectToHome";

const HEADER_HEIGHT = 64;

export function InternalErrorPage() {
  const tCommon = useTranslations(Namespace.COMMON);
  const {
    token: { sizeXS, colorBorderSecondary },
  } = theme.useToken();

  useRedirectToHome();

  return (
    <div>
      <Flex
        align="center"
        justify="center"
        style={{
          height: HEADER_HEIGHT,
          background: "#fff",
          borderBottom: `1px solid ${colorBorderSecondary}`,
        }}
      >
        <Link href={supplierRoutes.home}>
          <Image
            src="/images/trustana_logo_with_text.svg"
            alt="Trustana Logo"
            width={160}
            height={32}
          />
        </Link>
      </Flex>
      <Flex
        vertical
        gap={sizeXS}
        align="center"
        justify="center"
        className="absolute left-0 right-0 bottom-0 bg-[#F7F7FA]"
        style={{ top: HEADER_HEIGHT }}
      >
        <Title style={{ margin: 0 }} level={5}>
          {tCommon("errorScreens.internalError.title")}
        </Title>
        <Text size="SM">
          {tCommon("errorScreens.internalError.description")}
        </Text>
      </Flex>
    </div>
  );
}
