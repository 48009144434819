/* eslint-disable jsx-a11y/html-has-lang */

"use client";

import { InternalErrorPage } from "@/src/components/errorScreens/InternalErrorPage";

export default function GlobalError() {
  return (
    <html>
      <body>
        <InternalErrorPage />
      </body>
    </html>
  );
}
